<template>

  <video id="background" playsinline muted autoplay loop>
    <source src="https://chimbacomingsoon.s3.us-east-2.amazonaws.com/Zoe.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>

  <img class="logoMain" src="../assets/logo.png" alt="">
  
  
  <h1 class="comingSoon">COMING SOON</h1>

  <div @click="playPause()" class="now paused playing" id="music">
    <span class="bar n1"></span>
    <span class="bar n2"></span>
    <span class="bar n3"></span>
    <span class="bar n4"></span>
    <span class="bar n5"></span>
    <span class="bar n6"></span>
    <span class="bar n7"></span>
    <span class="bar n8"></span>
  </div>

  <audio id="audio" loop>
    <source src="../assets/music.mp3" type="audio/mpeg">
    Your browser does not support the audio element.
  </audio>

  <img @click="twitterOpen()" class="twitter" src="../assets/twitter.svg" alt="">
  
</template>

<script>
export default {
  name: 'HomeMain',
  data(){
    return{
      playing: false,
    }
  },
  methods:{
    twitterOpen()
    {
      window.open("https://twitter.com/ChimbaStudio")
    },
    playPause()
    {
      var player = document.getElementById("music");
      player.classList.toggle("paused");

      var audio = document.getElementById("audio");
      
      if(this.playing)
      {
        audio.pause();
        this.playing = false;
      }
      else
      {
        audio.play();
        this.playing = true;
      }
    }
  }
}
</script>

<style>
#background {
  /* Full height */
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;

  opacity: 0.7;
  z-index: 2;
}

@media screen and (max-width: 600px) {
  #background {
    position: absolute;
    width: 175%;
    overflow-x: hidden;
    left: 0;
    transform: translateX(-35%); /* Adjust this value as needed */
  }
  body{
    overflow: hidden !important;
  }
  .comingSoon
  {
    width: 105% !important;
  }
}
.logoMain
{
  position: absolute;
  width: 20vh;
  top: 3%;
  left: 5%;
  cursor: pointer;
  z-index: 50;
}
.twitter{
  position: absolute;
  width: 10vh;
  bottom: 6%;
  right: 5%;
  cursor: pointer;
  z-index: 50;
}
.comingSoon
{
  z-index: 50;
  position: absolute;
  width: 100%;
  top: 25vh;
  font-weight: 900;
  letter-spacing: 1vh;
  font-size: 10vw;
  color: #FEBA02;

  --x-offset: -0.0625em;
  --y-offset: 0.0625em;
  --stroke: 0.025em;
  --background-color: black;
  --stroke-color: transparent;
  
  text-shadow: 
    
    var(--x-offset)
    var(--y-offset)
    0px
    var(--background-color), 
    
    calc( var(--x-offset) - var(--stroke) )
    calc( var(--y-offset) + var(--stroke) )
    0px
    var(--stroke-color);
  
}
#music
{
  cursor: pointer;
  z-index: 50;
}
.playing {
  position: absolute;
  bottom: 5%;
  left: 5%;
  width: auto;
  z-index: 50;
}
.playing:after {
  content:"Playing";
  display: block;
  width: 100%;
  margin-top: 10px;
}
.now.playing .bar {
  display: inline-block;
  position: relative;
  margin-right: 1px;
  width: 10px;
  height: 1px;
  overflow: hidden;
  background: linear-gradient(to bottom, #FEBA02, #FEBA20);
  color: transparent;
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.paused.playing .bar {
  animation-iteration-count: 1;
}
.paused.playing:after {
  content: "Paused";
}
.n1 {
  animation-delay: 0.5s;
}
.n2 {
  animation-delay: 0.2s;
}
.n3 {
  animation-delay: 1.2s;
}
.n4 {
  animation-delay: 0.9s;
}
.n5 {
  animation-delay: 2.3s;
}
.n6 {
  animation-delay: 1.3s;
}
.n7 {
  animation-delay: 3.1s;
}
.n8 {
  animation-delay: 1.9s;
}
@keyframes pulse {
  0% {
    height: 1px;
    margin-top: 0;
  }
  10% {
    height: 40px;
    margin-top: -40px;
  }
  50% {
    height: 20px;
    margin-top: -20px;
  }
  60% {
    height: 30px;
    margin-top: -30px;
  }
  80% {
    height: 60px;
    margin-top: -60px;
  }
  100% {
    height: 1px;
    margin-top: 0;
  }
}
</style>
